<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';
    import LeadChip from '../components/LeadChip.vue';
    import { LeadActivity, LeadDto, LeadState } from '@containex/portal-backend-dto';
    import Panel from 'primevue/panel';
    import Divider from 'primevue/divider';
    import { useAsyncTask } from 'vue-concurrency';
    import { useLeadAction } from '../composables/leads';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useDateFormatter } from '@/composables/date-format';
    import { parseISO } from 'date-fns';
    import { isMobile } from '@/util/breakpoints';
    import { ref, type Ref } from 'vue';
    import EditLeadDialog from '../dialogs/EditLeadDialog.vue';
    import CompleteLeadDialog from '../dialogs/CompleteLeadDialog.vue';
    import { startDownloadForBase64String } from '@/util/download';
    import useGlobalToast from '@/composables/useGlobalToast';
    import GenericBreadcrumb from '@/common/components/GenericBreadcrumb.vue';
    import { setDynamicTitle } from '@/util/pageTitleUtils.ts';

    const props = defineProps<{
        id: string;
    }>();

    const { t } = useI18n();
    const leadAction = useLeadAction();
    const { dateFormatter } = useDateFormatter();
    const showEditLeadDialog = ref(false);
    const showCompleteLeadDialog = ref(false);

    const currentlyDownloading: Ref<string[]> = ref([]);

    let lead: LeadDto;

    const task = useAsyncTask(async () => {
        lead = await leadAction.fetchLead(props.id);
        setDynamicTitle(lead.externalId);
    }).perform();

    async function handleUpdate(data: LeadDto): Promise<void> {
        lead = data;
        showEditLeadDialog.value = false;
        showCompleteLeadDialog.value = false;
        await leadAction.fetchAmountNewLeads();
    }

    async function downloadDocument(document: string): Promise<void> {
        const splitted = document.split('.');
        const filename = splitted[0];
        const extension = splitted[splitted.length - 1];

        if (filename == null) {
            const { errorToastOptions, addToast } = useGlobalToast();
            addToast({
                ...errorToastOptions,
                summary: 'ERROR.DOWNLOAD_DOCUMENT.SUMMARY',
                detail: 'ERROR.DOWNLOAD_DOCUMENT.DETAIL',
            });
        }

        currentlyDownloading.value.push(document);
        try {
            const documentAsBase64String = await leadAction.fetchLeadDocumentAsBase64String(lead.id, document);
            startDownloadForBase64String(filename ?? '', extension ?? '', documentAsBase64String);
        } catch {
            const { errorToastOptions, addToast } = useGlobalToast();
            addToast({
                ...errorToastOptions,
                summary: 'ERROR.DOWNLOAD_DOCUMENT.SUMMARY',
                detail: 'ERROR.DOWNLOAD_DOCUMENT.DETAIL',
            });
        } finally {
            currentlyDownloading.value = currentlyDownloading.value.filter((item) => item !== document);
        }
    }
</script>
<template>
    <div v-if="task.isSuccessful">
        <GenericBreadcrumb
            :back="[{ link: Navigation.Leads, title: t('NAVIGATION.MENU_ITEM.LEADS') }]"
            :title="`${t('ACCOUNT.LEAD_DETAILS.TITLE')} ${lead.externalId}`"
        ></GenericBreadcrumb>

        <div class="header">
            <div>
                <div class="title">
                    <h2 class="text-2xl-bold-line-height-auto">
                        {{ t('ACCOUNT.LEAD_DETAILS.TITLE') }} {{ lead.externalId }}
                    </h2>
                    <LeadChip :state="lead.internalState" />
                </div>
                <span class="subtitle text-md-regular-line-height-auto"
                    >{{ t('ACCOUNT.LEAD_DETAILS.OWNER') }}: {{ lead.users.join(', ') }}</span
                >
            </div>
            <div
                v-if="lead.internalState !== LeadState.Done"
                class="edit"
                :class="{ 'mobile-button-container': isMobile }"
            >
                <Button
                    :class="{ 'mobile-button': isMobile }"
                    type="button"
                    outlined
                    icon="pi pi-pencil"
                    :label="isMobile ? '' : t('COMMON.EDIT')"
                    @click="showEditLeadDialog = true"
                />
                <Button
                    :class="{ 'mobile-button': isMobile }"
                    type="button"
                    icon="pi pi-check"
                    :label="isMobile ? '' : t('COMMON.SET_TO_DONE')"
                    @click="showCompleteLeadDialog = true"
                />
            </div>
        </div>

        <div class="documentation-container">
            <div class="content documentation-padding">
                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.INTERNAL_NOTE') }}
                </h3>
                <Textarea
                    :model-value="lead.internalNote === '' ? '-' : lead.internalNote"
                    class="textarea-readonly-style"
                    auto-resize
                    readonly
                ></Textarea>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.ACTIVITY') }}
                </h3>
                <div>{{ lead.activity != null ? t(`ACCOUNT.LEADS.ACTIVITIES.${lead.activity}`) : '-' }}</div>
                <Divider class="divider"></Divider>

                <template v-if="lead.activity === LeadActivity.NotInteresting && lead.activityText != null">
                    <h3 class="text-base-regular-line-height-auto subheader">
                        {{ t('ACCOUNT.LEAD_DETAILS.ACTIVITY_TEXT') }}
                    </h3>
                    <div>{{ lead.activityText }}</div>
                    <Divider class="divider"></Divider>
                </template>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.DOCUMENTS') }}
                </h3>
                <div>-</div>
            </div>
        </div>

        <Panel class="panel">
            <template #header>
                <h2 class="text-xl-bold-line-height-auto">
                    {{ lead.type ? lead.type : t('ACCOUNT.LEAD_DETAILS.REQUEST') }}
                </h2>
            </template>
            <div class="content">
                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.CREATED_AT') }}
                </h3>
                <div>{{ dateFormatter.formatDateTwoDigits(parseISO(String(lead.date))) }}</div>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.COMPANY') }}
                </h3>
                <div>
                    {{ lead.prospect.company_name }}<br />
                    {{ lead.prospect.street }} {{ lead.prospect.house_number }}<br />
                    {{ lead.prospect.country }} - {{ lead.prospect.postal_code }} {{ lead.prospect.city }}
                </div>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.CONTACT') }}
                </h3>
                <div>
                    {{ lead.prospect.gender }} {{ lead.prospect.firstname }} {{ lead.prospect.lastname }}<br />
                    {{ lead.prospect.phone }}<br />
                    {{ lead.prospect.email }}<br />
                    {{ t('COMMON.LANGUAGE') }}: {{ lead.prospect.language }}
                </div>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.FORWARDING_NOTE') }}
                </h3>
                <div>{{ lead.note }}</div>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.ATTACHMENTS') }}
                </h3>
                <div>
                    <ul class="download-list">
                        <li v-for="document in lead.documents" :key="document">
                            <span v-if="!currentlyDownloading.includes(document)" class="pi pi-download"></span>
                            <span v-else class="document-download-loading-spinner"><LoadingSpinner /></span>
                            <span class="document-download-link" @click="downloadDocument(document)">{{
                                document
                            }}</span>
                        </li>
                    </ul>
                </div>
                <Divider class="divider"></Divider>

                <h3 class="text-base-regular-line-height-auto subheader">
                    {{ t('ACCOUNT.LEAD_DETAILS.EMPLOYEE') }}
                </h3>
                <div>
                    {{ lead.owner.name }}<br />
                    {{ lead.owner.email }}<br />
                    {{ lead.owner.officePhone }}
                </div>
            </div>
        </Panel>
    </div>
    <div v-else-if="task.isRunning" class="loading-spinner">
        <LoadingSpinner />
    </div>

    <EditLeadDialog
        v-if="task.isSuccessful && lead.internalState !== LeadState.Done && showEditLeadDialog"
        :lead="lead"
        @close="showEditLeadDialog = false"
        @update="handleUpdate"
    />
    <CompleteLeadDialog
        v-if="task.isSuccessful && lead.internalState !== LeadState.Done && showCompleteLeadDialog"
        :lead="lead"
        @close="showCompleteLeadDialog = false"
        @update="handleUpdate"
    />
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: main.$spacing-5;
    }

    .header .edit {
        display: flex;
        gap: main.$spacing-3;
    }

    .header .title {
        display: flex;
        flex-wrap: wrap;
        gap: main.$spacing-3;
        align-items: center;
        overflow-wrap: anywhere;
    }

    .header .subtitle {
        color: main.$color-secondary;
    }

    .content {
        display: grid;
        grid-column: 1 / -1;
        align-items: center;
        gap: 1ch;
        container-type: inline-size;
    }

    @include main.for-breakpoint-lg {
        .content {
            grid-template-columns: minmax(min-content, 1fr) 3fr;
        }
    }

    .textarea-readonly-style {
        color: main.$color-text;
        opacity: 100%;
        border: none;
        overflow: auto;
        outline: none;
        background: transparent;

        box-shadow: none;

        // remove the resize handle on the bottom right
        resize: none;
    }

    .divider {
        grid-column: 1 / -1;
    }

    .subheader {
        color: main.$color-text-secondary;
    }

    .mobile-button-container {
        align-self: baseline;
        padding-top: main.$spacing-3;
        padding-bottom: main.$spacing-3;
    }

    .mobile-button {
        min-height: 48px;
        min-width: 48px;
    }

    .documentation-container {
        margin-top: main.$spacing-6;
        border: 1px solid main.$color-border-gray;
        border-radius: 6px;
    }

    .documentation-padding {
        padding: main.$spacing-5;
    }

    .panel {
        margin-top: main.$spacing-6;
    }

    :deep(.p-panel-header) {
        background: unset;
    }

    .loading-spinner {
        text-align: center;
    }

    .download-list {
        padding: 0;
        margin: 0;
    }

    .download-list li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-weight: bold;
        color: main.$color-primary-500;
    }

    .download-list li span {
        margin-right: main.$spacing-3;
    }

    .document-download-link {
        cursor: pointer;
    }

    .document-download-loading-spinner :deep(.p-progress-spinner) {
        width: 16px;
        height: 16px;
    }
</style>
